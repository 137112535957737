<template>
  <div>
     <van-nav-bar left-arrow left-text="返回" title="预约列表" @click-left="goback"/>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <!-- <van-card  v-for="(item,index) in list"  :key="index" thumb="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1568267523,2209717438&fm=26&gp=0.jpg">
          <template #desc>
            <ul>
              <li  style="margin:0.2rem 0.2rem 0rem 0rem">门店:{{item.store==null?'':item.store.name}}</li>
              <li  style="margin:0.2rem 0.2rem 0rem 0rem">预估货量:{{item.predict_weight}}</li>
              <li  style="margin:0.2rem 0.2rem 0rem 0rem">预约时间:{{item.appointmenttime_text}}</li>
              <li  style="margin:0.2rem 0.2rem 0rem 0rem">详细地址:{{item.address}}</li>
              <li  style="margin:0.2rem 0.2rem 0rem 0rem">备注:</li> 
            </ul>
          </template>
          <template #footer>
            <van-button size="mini"  v-if="item.status===0" @click="subscribeCancelMethod(item.id)">'取消预约</van-button>
            <van-button size="mini" disabled v-else>已取消</van-button>
          </template>
        </van-card> -->
        <ul class="subs-wrap">
          <li class="subs-item" v-for="(item,index) in list"  :key="index">
            <span class="title">门店：{{item.store==null?'':item.store.name}}</span>
            <span>预估货量：{{item.predict_weight}}</span>
            <span>预约时间：{{item.appointmenttime_text}}</span>
            <span>详细地址：{{item.address}}</span>
            <span>备注：{{item.remarkcontent}}</span>
            <div class="subs-img" v-if="item.appointmentimage">
              <img :src="baseUrl + item.appointmentimage" alt="">
            </div>
            <div class="footer-wrap">
              <button class="footer-btn" v-if="item.status===0" @click="subscribeCancelMethod(item.id)">取消预约</button>
              <button class="footer-btn footer-btn1" v-else>已取消</button>
            </div>
          </li>
        </ul>
        
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {getSubscribe,subscribeCancel} from '../tool/api'
export default {
    data(){
        return{
            baseUrl: 'https://express.sugidrug.com//',
            list: [],
            isLoading: false,
            loading: false,
            finished: false,
            query: { 
                pageNo:0,
                pageSize:10
            }
        }
    },
    created(){
        getSubscribe().then(a=>{
            this.list=a.data;
        })
    },
    methods: {
       goback() {
      history.back()
    },
        subscribeCancelMethod(val){
            subscribeCancel(val).then(a=>{
                if(a.code===1){
                    Toast("取消成功");
                     getSubscribe().then(a=>{
                        this.list=a.data;
                     })
                }else{
                    Toast("取消失败");
                }
                
            })
        },
        onRefresh() {
            setTimeout(() => {
                Toast('刷新成功');
                this.isLoading = false;
                this.count++;
            }, 1000);
        }, 
        onLoad() { 
            setTimeout(() => {
                if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
                }
                this.finished = true;
            }, 1000);
        },
  },
}
</script>

<style>
.subs-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px 0 15px;
    margin: 10px 10px 0 10px;
    box-sizing: border-box;
    border-radius: 5px;
    background: #fff;
    color: #999;
    font-size: 12px;
}
.subs-item span {
    margin-top: 5px;
}

.subs-img {
    position: relative;
    width: 30%;
    height: 0;
    margin-top: 10px;
    padding-bottom: 30%;
    overflow: hidden;
    box-sizing: border-box;
}
.subs-img img  {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.title {
    color: #333;
    font-size: 14px;
}

.footer-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    margin-top: 15px;
    border-top: 1px dashed #f2f3f5;
}

.footer-wrap .footer-btn {
    width: 70px;
    height: 26px;
    border: 0;
    border-radius: 15px;
    color: #fff;
    font-size: 12px;
    background: red;
}

.footer-wrap .footer-btn1 {
    color: #999;
    background: #f2f3f5;
}

</style>
